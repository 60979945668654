import axios from 'axios';

import { HOST_API } from 'src/config-global';

axios.defaults.headers.common.Accept = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';

const instance = axios.create({
  baseURL: `${HOST_API}/api`,
});

instance.interceptors.request.use((config) => {
  const token = sessionStorage.getItem('accessToken');
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

function apiGet(url_code, getFilters = null, oid = null, contact_id = null) {
  let url = url_code != null ? apiUrl[url_code] : '';

  if (url === null || url.length === 0) {
    console.log('!!! GET ERROR : No url code found');
    return '';
  }

  if (oid && url.indexOf('{oid}') > 0) {
    url = url.replace('$', '');
    const temp = url.split('{oid}');
    url = temp[0] + oid + temp[1];
  }

  if (contact_id && url.indexOf('{contact_id}') > 0) {
    url = url.replace('$', '');
    const temp = url.split('{contact_id}');
    url = temp[0] + contact_id + temp[1];
  }

  let include;
  let params;
  let filters = [];
  let page;
  let limit = 0;
  let search = null;
  let sort = null;

  if (getFilters != null) {
    include = getFilters.include != null ? getFilters.include : include;
    page = getFilters.page != null ? getFilters.page : page;
    limit = getFilters.limit != null ? getFilters.limit : limit;
    params = getFilters.params != null ? getFilters.params : params;
    filters = getFilters.filters != null ? getFilters.filters : filters;
    search = getFilters.search != null ? getFilters.search : search;
    sort = getFilters.sort != null ? getFilters.sort : sort;
  }
  let urlPart = '';
  let operator = '';
  if (include != null && include.length > 0) {
    include.forEach((element) => {
      operator = operator === '' ? '?' : '&';
      urlPart += operator === '?' ? '?include=' : ',';
      urlPart += element;
    });
  }
  if (page != null && page > 0) {
    operator = operator === '' ? '?' : '&';

    urlPart += `${operator}page=${page}`;
  }
  if (limit != null && limit > 0) {
    operator = operator === '' ? '?' : '&';

    urlPart += `${operator}limit=${limit}`;
  }

  if (search != null && search.length > 0) {
    operator = operator === '' ? '?' : '&';

    urlPart += `${operator}search=${search}`;
  }

  if (sort != null && sort.length > 0) {
    operator = operator === '' ? '?' : '&';

    urlPart += `${operator}sort=${sort}`;
  }
  url += urlPart;

  url = setParamsAndFiltersUrl(url, params, operator, filters);

  return instance.get(url);
}

function apiPost(url_code, payload, getFilters = null, op = 'post') {
  let url = url_code != null ? apiUrl[url_code] : '';

  if (url === null || url.length === 0) {
    console.log('!!! POST ERROR : No url code found');
    return '';
  }

  let params = [];
  if (getFilters != null) {
    params = getFilters.params != null ? getFilters.params : params;
  }

  url = setParamsAndFiltersUrl(url, params, '');

  return op === 'post' ? instance.post(url, payload) : instance.put(url, payload);
}

function apiPostGet(url_code, payload, getFilters = null, op = 'post') {
  let url = url_code != null ? apiUrl[url_code] : '';

  if (url === null || url.length === 0) {
    console.log('!!! POST ERROR : No url code found');
    return '';
  }

  let include;
  let params;
  let filters = [];
  let page;
  let limit = 0;
  let search = null;
  let sort = null;

  if (getFilters != null) {
    include = getFilters.include != null ? getFilters.include : include;
    page = getFilters.page != null ? getFilters.page : page;
    limit = getFilters.limit != null ? getFilters.limit : limit;
    params = getFilters.params != null ? getFilters.params : params;
    filters = getFilters.filters != null ? getFilters.filters : filters;
    search = getFilters.search != null ? getFilters.search : search;
    sort = getFilters.sort != null ? getFilters.sort : sort;
  }
  let urlPart = '';
  let operator = '';
  if (include != null && include.length > 0) {
    include.forEach((element) => {
      operator = operator === '' ? '?' : '&';
      urlPart += operator === '?' ? '?include=' : ',';
      urlPart += element;
    });
  }
  if (page != null && page > 0) {
    operator = operator === '' ? '?' : '&';

    urlPart += `${operator}page=${page}`;
  }
  if (limit != null && limit > 0) {
    operator = operator === '' ? '?' : '&';

    urlPart += `${operator}limit=${limit}`;
  }

  if (search != null && search.length > 0) {
    operator = operator === '' ? '?' : '&';

    urlPart += `${operator}search=${search}`;
  }

  if (sort != null && sort.length > 0) {
    operator = operator === '' ? '?' : '&';

    urlPart += `${operator}sort=${sort}`;
  }
  url += urlPart;

  url = setParamsAndFiltersUrl(url, params, operator, filters);

  return op === 'post' ? instance.post(url, payload) : instance.put(url, payload);
}

function apiDelete(url_code, getFilters = null) {
  let url = url_code != null ? apiUrl[url_code] : '';

  if (url === null || url.length === 0) {
    console.log('!!! DELETE ERROR : No url code found');
    return '';
  }

  let params = [];
  if (getFilters != null) {
    params = getFilters.params != null ? getFilters.params : params;
  }

  url = setParamsAndFiltersUrl(url, params, '');

  return instance.delete(url);
}

function setParamsAndFiltersUrl(url, params, operator, filters = null) {
  if (params != null) {
    Object.keys(params).forEach((key) => {
      const value = params[key];
      const find = `$(${key})`;
      if (url.includes(find)) {
        url = url.replace(find, value);
      } else {
        operator = operator === '' ? '?' : '&';
        url += `${operator}${key}=${value}`;
      }
    });
  }
  if (filters != null) {
    Object.keys(filters).forEach((key) => {
      const value = filters[key];
      key = key.replace('___', '.');
      const str = `filter[${key}]=${value}`;
      operator = operator === '' ? '?' : '&';
      url += `${operator}${str}`;
    });
  }
  return url;
}

const apiUrl = {
  GET_JOBS: '/jobs',
  GET_USERRECEIPTCATEGORIES: '/userreceiptcategories',
  GET_CUSTOMERS: '/customers',
  GET_CUSTOMERSPLAIN: '/customersdropdown',
  AUTOMATICUPDATE_CUSTOMERS: '/customers/automaticupdate',
  GET_PHONENUMBEREMAILS: '/phoneNumberEmails',
  GET_SUBACCOUNTSDETAILS: '/service-provider/bandwidth/management/sub-accounts/',
  GET_USERS: '/users',
  GET_ROLES: '/roles',
  GET_ROLESPLAIN: '/rolesPlain',
  POST_ROLE: '/roles',
  PUT_ROLE: '/roles/($id)',
  DELETE_ROLE: '/roles/($id)',

  // Reports
  GET_RECEIPTS: '/userReceipts',
  GET_RECEIPTSREPORT: '/userspictures',

  // Domains
  GET_DOMAIN_DETAILS: '/get/servers/domain/details/usage/information',
};

const Api = {
  // customers
  automaticUpdate: () => instance.post('/customers/automaticupdate'),

  // Users
  getUsers: () => instance.get('/users'),
  getUser: (id) => instance.get(`/users/${id}`),
  addUser: (payload) => instance.post(`/users`, payload),
  editUser: (id, payload) => instance.post(`/users/${id}`, payload),
  deleteUser: (id) => instance.delete(`/users/${id}`),

  // Roles
  getRoles: () => instance.get('/roles'),
  getRole: (id) => instance.get(`/roles/${id}`),
  putRole: (id, payload) => instance.put(`/roles/${id}`, payload),
  postRole: (payload) => instance.post(`/roles`, payload),
  deleteRole: (id) => instance.delete(`/roles/${id}`),

  // userReceipts
  deleteUserReceipt: (id) => instance.delete(`/userReceipts/${id}`),
  getUserReceiptWInfo: (id) => instance.get(`/userReceiptsWInfo/${id}`),

  // userReceiptCategories
  getUserReceiptCategory: (id) => instance.get(`/userreceiptcategories/${id}`),
  addUserReceiptCategory: (payload) => instance.post(`/userreceiptcategories`, payload),
  editUserReceiptCategory: (id, payload) => instance.post(`/userreceiptcategories/${id}`, payload),
  deleteUserReceiptCategory: (id) => instance.delete(`/userreceiptcategories/${id}`),

  addSubCategory: (id, payload) => instance.post(`/addsubcategory/${id}`, payload),
  deleteSubCategory: (sub_id) => instance.delete(`/deletesubcategory/${sub_id}`),

  // jobs
  addJob: (payload) => instance.post(`/jobs`, payload),
  editJob: (id, payload) => instance.post(`/jobs/${id}`, payload),
  deleteJob: (id) => instance.delete(`/jobs/${id}`),

  // Permissions
  getPermissions: () => instance.get(`/permissions`),

  // All PBX Integration ///////////////////////////////////////////////////
  // Clients
  clientsInfo: () => instance.get('/getclients/info/customers'),
  clientsInfoSelect: (current, last) =>
    instance.get(`/getclients/info/customersselect/${current}/${last}`),
  clientsInfoSelectMonthsOptions: () => instance.get('/getclients/info/customersmonths/invoices'),

  // Voip Innovation
  voipInfoOfClients: () => instance.get('/getclients/info/from/voipinn'),
  voipGroupDID: (groupId) => instance.get(`/getdidgroup/info/from/voipinn/${groupId}`),
  voipInfoOfEndGroups: () => instance.get('/getendpoint/info/from/voipinn'),
  voipInfoOfActivePorts: () => instance.get('/getactive/porting/info/from/voipinn'),

  // CloudFlare
  getDnsRecords: (page = 1) => instance.get(`/get/dns/records?page=${page}`),

  // PBX
  getDomainsListPrev: () => instance.get('/show/all/domains/prev'),
  getDomainsListPrevFast: () => instance.get('/show/all/domains/prev/fast'),
  getDomainsListPrevFastDest: () => instance.get('/show/all/domains/prev/fastdest'),
  getDomainsList: () => instance.get('/show/all/domains'),
  getDomainsRelations: () => instance.get('/get/servers/domain/relations'),
  showDomain: (domain) => instance.get(`/show/all/domain/${domain}`),
  addPbxDomain: (payload) => instance.post('/add/new/domain', payload),
  addPbxDomainDestination: (payload) => instance.post('/add/new/destination/to/domain', payload),
  addPbxDomainExtension: (payload) => instance.post('/add/new/extension/to/domain', payload),
  addPbxDomainRinggroup: (payload) => instance.post('/add/new/ringgroup/to/domain', payload),
  addPbxDomainFax: (payload) => instance.post('/add/new/fax/to/domain', payload),
  editFixPbxDomain: (payload) => instance.post('/edit/fix/domain', payload),
  moveDevices: (payload) => instance.post('/move/devices/to/domain', payload),
  moveDomains: (payload) => instance.post('/move/domains/to/server', payload),
  flushCache: (domain) => instance.get(`/flush/pbx/${domain}`),
  reloadXml: (domain) => instance.get(`/reload/xml/${domain}`),
  reloadDnsCache: () => instance.get('/reload/dns/cache'),
  unregisterDevice: (ext, domain) => instance.get(`/unregister/device/${ext}/in/${domain}`),
  disableDomain: (domain) => instance.get(`/disable/domain/${domain}`),
  enableDomain: (domain) => instance.get(`/enable/domain/${domain}`),
  getRegistration: (domain) => instance.get(`/registrations/by/domain/${domain}`),
  getServersInformation: () => instance.get('/get/servers/status'),
  getServersActiveCallsAndChannels: () => instance.get('/get/servers/active/callsandchannels'),
  getServerUsageInformation: (payload) =>
    instance.get(
      `/get/servers/usage/information?begin=${payload.begin}&end=${payload.end}`,
      payload
    ),
  getServerDomainDetailsUsageInformation: (payload) =>
    instance.get(
      `/get/servers/domain/details/usage/information?begin=${payload.begin}&end=${
        payload.end
      }&domain=${payload.domain}${payload.missed === 1 ? '&missed=1' : '&missed=0'}&page=${
        payload.page
      }&limit=${payload.limit}&search=${payload.search ? payload.search : ''}&sort=${
        payload.sort ? payload.sort : ''
      }`,
      payload
    ),

  // Bandwidth integration
  bwGetSubAccountsDetails: () => instance.get('/get/bandwidth/subaccounts/details'),
  bwGetSubAccountDetails: (id) => instance.get(`/get/bandwidth/subaccount/details/${id}`),
  bwMyAvailablesTns: () => instance.get('/get/bandwidth/available/tns/byapi'),
  bwToBuyAvailablesTns: (code) => instance.get(`/get/bandwidth/available/tns/tobuy/${code}`),
  bwBuyNumbers: (payload) => instance.post(`/get/bandwidth/buynumbers`, payload),
  bwMoveNumbers: (payload) => instance.post(`/get/bandwidth/movenumbers`, payload),
  bwCheckOrder: (order) => instance.get(`/get/bandwidth/buynumber/checkorder/${order}`),
  bwCreateSubaccount: (payload) => instance.post(`/bandwidth/create/subaccount`, payload),
  bwActivateSubaccountLocationSmsMms: (siteId) => instance.get(`/bandwidth/activate/subaccount/smsmms/${siteId}`),

  // Management
  // Sub Accounts
  getSubAccounts: () => instance.get(`/service-provider/bandwidth/management/sub-accounts/`),
  syncSubAccountsFromDashboard: () => instance.post(`/service-provider/bandwidth/management/sub-accounts/dashboard-sync`),
  managesubAccountCampaign: (action, payload) =>
    instance.post(
      `/service-provider/bandwidth/management/sub-accounts/campaigns/${action}`,
      payload
    ),
  // Campaings
  getCampaigns: () => instance.get(`/service-provider/bandwidth/management/campaigns/cloud`),

  // All PBX END//////////////////////////////////////////////////////////////////

  // Phone to email
  getPhoneToEmails: () => instance.get('/phoneNumberEmails'),
  addPhoneToEmail: (payload) => instance.post(`/phoneNumberEmails`, payload),
  editPhoneToEmail: (id, payload) => instance.post(`/phoneNumberEmails/${id}`, payload),
  deletePhoneToEmail: (phone) => instance.delete(`/phoneNumberEmails/${phone}`),
  deleteEmailFromPhone: (phone, email) =>
    instance.delete(`/phoneNumberEmails/${phone}/delete/${email}`),
  addEmailFromPhone: (phone, payload) => instance.post(`/phoneNumberEmails/${phone}/add`, payload),
  editEmailFromPhone: (phone, email, payload) =>
    instance.post(`/phoneNumberEmails/${phone}/edit/${email}`, payload),

  // Generic Calls
  getEntities: (apiParams) =>
    apiGet(
      apiParams.dataUrl.code,
      apiParams.filters,
      apiParams.dataUrl.oid ? apiParams.dataUrl.oid : null
    ),

  getContactEntities: (apiParams) =>
    apiGet(
      apiParams.dataUrl.code,
      apiParams.filters,
      apiParams.dataUrl.oid ? apiParams.dataUrl.oid : null,
      apiParams.dataUrl.contact_id ? apiParams.dataUrl.contact_id : null
    ),
  saveEntity: (apiParams) =>
    apiPost(apiParams.dataUrl.code, apiParams.payload, apiParams.filters, 'post'),

  updateEntity: (apiParams) =>
    apiPost(apiParams.dataUrl.code, apiParams.payload, apiParams.filters, 'put'),
  deleteEntity: (apiParams) => apiDelete(apiParams.dataUrl.code, apiParams.filters),
  updateEntityPost: (apiParams) =>
    apiPost(apiParams.dataUrl.code, apiParams.payload, apiParams.filters, 'post'),

  updateEntityPostGet: (apiParams) =>
    apiPostGet(apiParams.dataUrl.code, apiParams.payload, apiParams.filters, 'post'),
};

export default Api;
