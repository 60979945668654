import {
  newSlice,
  _fetchEntitiesService,
  _loadEvents,
  _createEntityService,
  _updateEntityServicePost,
  _deleteEntityService,
  _updateOnlyEntityService,
} from "../generic/sliceFunctions";

const entitiesName = "roles";

const entitiesSlice = newSlice(entitiesName);
const events = _loadEvents(entitiesSlice);

export const entitiesSelector = (state) => state[entitiesName];
export default entitiesSlice.reducer;

export function fetchEntitiesService(dataSlice) {
  dataSlice.dataUrl.code = "GET_ROLES";
  return _fetchEntitiesService(dataSlice, events);
}
export function fetchPlainEntitiesService(dataSlice) {
  dataSlice.dataUrl.code = "GET_ROLESPLAIN";
  return _fetchEntitiesService(dataSlice, events);
}
export function createEntityService(dataSlice) {
  dataSlice.dataUrl.code = "POST_ROLE";
  return _createEntityService(dataSlice, events);
}

export function updateEntityService(dataSlice) {
  dataSlice.dataUrl.code = "PUT_ROLE";
  return _updateEntityServicePost(dataSlice, events);
}

export function deleteEntityService(dataSlice) {
  dataSlice.dataUrl.code = "DELETE_ROLE";
  return _deleteEntityService(dataSlice, events);
}
export function updateOnlyEntityService(payload) {
  return _updateOnlyEntityService(payload, events);
}
