import {
  newSlice,
  _fetchEntitiesService,
  _loadEvents
} from "../generic/sliceFunctions";

const entitiesName = "customers";

const entitiesSlice = newSlice(entitiesName);
const events = _loadEvents(entitiesSlice);

export const entitiesSelector = (state) => state[entitiesName];
export default entitiesSlice.reducer;

export function fetchEntitiesService(dataSlice) {
  dataSlice.dataUrl.code = "GET_CUSTOMERS";
  return _fetchEntitiesService(dataSlice, events);
}
export function fetchPlainEntitiesService(dataSlice) {
  dataSlice.dataUrl.code = "GET_CUSTOMERSPLAIN";
  return _fetchEntitiesService(dataSlice, events);
}

