import React, { lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import MainLayout from 'src/layouts/main';
import { useAuthContext } from 'src/auth/hooks';
// config
// import { PATH_AFTER_LOGIN } from 'src/config-global';
//
import AuthClassicLayout from 'src/layouts/auth/classic';
import { isEmpty, filter } from 'lodash';
import { mainRoutes, HomePage } from './main';
import { authRoutes } from './auth';
import { authDemoRoutes } from './auth-demo';
import { dashboardRoutes } from './dashboard';
import { managementRoutes } from './management';
import { managementPBXRoutes } from './managementpbx';
import { managementBandwidthRoutes } from './managementbandwidth';

import { reportRoutes } from './report';

import { componentsRoutes } from './components';

import Utils from '../../utils/utils';

const JwtLoginPage = lazy(() => import('src/pages/auth/jwt/login'));

// ----------------------------------------------------------------------

export default function Router(props) {
  const { env } = props;

  const { user } = useAuthContext();
  const routes = [
    // SET INDEX PAGE WITH SKIP HOME PAGE
    // {
    //   path: '/',
    //   element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    // },

    // ----------------------------------------------------------------------

    // SET INDEX PAGE WITH HOME PAGE
    {
      path: '/',
      element: (
        <AuthClassicLayout>
          <JwtLoginPage />
        </AuthClassicLayout>
      ),
    },

    // Auth routes
    ...authRoutes,
    ...authDemoRoutes,

    // Dashboard routes
    ...dashboardRoutes,

    // Management routes
    ...managementRoutes,
    ...managementPBXRoutes,
    ...managementBandwidthRoutes,
    // Management PBX routes with props
    // ...managementPBXRoutes.map((route) => ({
    //   ...route,
    //   element: React.cloneElement(route.element, {
    //     // Add your props here
    //     myEnv: env,
    //     // ... add more props as needed
    //   }),
    // })),

    // Report routes
    ...reportRoutes,

    // Main routes
    ...mainRoutes,

    // Components routes
    ...componentsRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ];

  let permissionRoutes = routes;

  if (user) {
    const permissionTempRoutes = filter(routes, (itemA) =>
      itemA && !isEmpty(itemA.permissions) ? Utils.userHasPermission(user, itemA.permissions) : true
    );

    permissionRoutes = permissionTempRoutes.map((item, index) => ({
      ...item,
      children: item.children
        ? item.children.filter((itemC) =>
            !isEmpty(itemC.permissions) ? Utils.userHasPermission(user, itemC.permissions) : true
          )
        : null,
    }));
  }

  return useRoutes(permissionRoutes);
}
