import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import { useProps } from '@mui/x-data-grid/internals';

// ----------------------------------------------------------------------

// OVERVIEW

const DashboardPage = lazy(() => import('src/pages/managementPBX/dashboard'));
const DomainInfoPage = lazy(() => import('src/pages/managementPBX/domainInfo'));

// eslint-disable-next-line import/extensions
const DomainManageCreate = lazy(() => import('src/pages/managementPBX/domainManage/create.js'));
// eslint-disable-next-line import/extensions
const DomainManageUpdate = lazy(() => import('src/pages/managementPBX/domainManage/update.js'));
// eslint-disable-next-line import/extensions
const DomainManageRegenerate = lazy(() => import('src/pages/managementPBX/domainManage/regenerate.js'));
// eslint-disable-next-line import/extensions
const DomainMoveDevices = lazy(() => import('src/pages/managementPBX/domainManage/moveDevices.js'));
// eslint-disable-next-line import/extensions
const DomainMoveDomains = lazy(() => import('src/pages/managementPBX/domainManage/moveDomains.js'));
const ForwardtoEmail = lazy(() => import('src/pages/managementPBX/forwardtoEmail/forwardtoEmail'));

// ----------------------------------------------------------------------

export const managementPBXRoutes = [
  {
    path: 'managementPBX',
    permissions: ["menu_managementPBX"],
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { path: 'dashboard', element: <DashboardPage />, permissions: ["pbx_user"] },
      { path: 'domainInfo', element: <DomainInfoPage />, permissions: ["pbx_user"] },

      { path: 'create', element: <DomainManageCreate />, permissions: ["pbx_user","pbx_domainCreation","pbx_superAdmin"] },
      { path: 'update', element: <DomainManageUpdate />, permissions: ["pbx_user"] },
      { path: 'regenerate', element: <DomainManageRegenerate />, permissions: ["pbx_user","pbx_domainEdit","pbx_superAdmin"] },
      { path: 'moveDevices', element: <DomainMoveDevices />, permissions: ["pbx_user"] }, 

      { path: 'moveDomains', element: <DomainMoveDomains />, permissions: ["pbx_superAdmin"] }, 
      { path: 'forwardtoEmail', element: <ForwardtoEmail />, permissions: ["phoneNumberEmail_menu"] } 
    ],
  },
];
