import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// OVERVIEW

const UserRolesPage = lazy(() => import('src/pages/management/roles'));

const UserListPage = lazy(() => import('src/pages/dashboard/user/list'));

const UserCreatePage = lazy(() => import('src/pages/dashboard/user/new'));
const UserEditPage = lazy(() => import('src/pages/dashboard/user/edit'));


const CustomersPage = lazy(() => import('src/pages/management/customers'));
const UserReceiptCategoryPage = lazy(() => import('src/pages/management/userReceiptCategory'));
// ----------------------------------------------------------------------

export const managementRoutes = [
  {
    path: 'management',
    permissions: ["menu_management"],
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [      
      {
        path: 'user',
        permissions: ["management_users"],
        children: [
          { element: <UserListPage />, index: true },        
          { path: 'list', element: <UserListPage /> },
          { path: 'new', element: <UserCreatePage /> },
          { path: ':id/edit', element: <UserEditPage /> },   
        ],
      }, 
      { path: 'roles', element: <UserRolesPage />, permissions: ["management_roles"] },
      { path: 'customers', element: <CustomersPage />, permissions: ["management_customers"] },
      { path: 'userreceiptcategories', element: <UserReceiptCategoryPage />, permissions: ["management_userreceiptcategories"] }
    ],
  },
];
