import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// OVERVIEW

const ReportUserReceiptsPage = lazy(() => import('src/pages/reports/userReceipts'));
const JobsPage = lazy(() => import('src/pages/reports/jobs'));

// ----------------------------------------------------------------------

export const reportRoutes = [
  {
    path: 'report',
    permissions: ["menu_report"],
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [       
      { path: 'userReceipts', element: <ReportUserReceiptsPage /> , permissions: ["report_technicianImages"]},
      { path: 'jobs', element: <JobsPage /> , permissions: ["report_jobs"]}
    ],
  },
];
