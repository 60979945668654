import { createSlice } from "@reduxjs/toolkit";

export const pbxDomainListsSlice = createSlice({
  name: "pbxDomainLists",
  initialState: {
    clients: [],
    voipInn: [],
    bandwidth: [],
    domains: [],
    domainsListOnly: [],
    domainsPreview: [],
    serverStatus: [],
    selectedServer:"",
    selectedDomain:"",
    selectedExtension:""
  },

  reducers: {
    // increment: (state) => {
    //   // Redux Toolkit allows us to write "mutating" logic in reducers. It
    //   // doesn't actually mutate the state because it uses the Immer library,
    //   // which detects changes to a "draft state" and produces a brand new
    //   // immutable state based off those changes
    //   state.value += 1
    // },
    // decrement: (state) => {
    //   state.value -= 1
    // },
    // incrementByAmount: (state, action) => {
    //   state.value += action.payload
    // },

    setClients: (state, action) => {
      state.clients = action.payload;
    },
    setVoipInn: (state, action) => {
      state.voipInn = action.payload;
    },
    setBandwidth: (state, action) => {
      state.bandwidth = action.payload;
    },
    setDomains: (state, action) => {
      state.domains = action.payload;
    },
    setDomainsListOnly: (state, action) => {
      state.domainsListOnly = action.payload;
    },
    setDomainsPreview: (state, action) => {
      state.domainsPreview = action.payload;
    },
    setServerStatus: (state, action) => {
      state.serverStatus = action.payload;
    },
    setSelectedServer: (state, action) => {
      state.selectedServer = action.payload;
    },
    setSelectedDomain: (state, action) => {
      state.selectedDomain = action.payload;
    },
    setSelectedExtension: (state, action) => {
      state.selectedExtension = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setClients,
  setVoipInn,
  setBandwidth,
  setDomains,
  setDomainsListOnly,
  setDomainsPreview,
  setServerStatus,
  setSelectedServer,
  setSelectedDomain,
  setSelectedExtension
} = pbxDomainListsSlice.actions;

export default pbxDomainListsSlice.reducer;
