import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import checkoutReducer from './slices/checkout';
import rolesReducer from "./slices/rolesSlice";
import customersReducer from "./slices/customersSlice";

import pbxInfoReducer from "./slices/pbxDomainListsSlice";

// ----------------------------------------------------------------------

const checkoutPersistConfig = {
  key: 'checkout',
  storage,
  keyPrefix: 'redux-',
};

export const rootReducer = combineReducers({
  checkout: persistReducer(checkoutPersistConfig, checkoutReducer),
  roles:rolesReducer,
  pbxInfo:pbxInfoReducer,
  customers:customersReducer
});
