import { merge } from 'lodash';

const apiGetFiltersDefault = {
  include: [],
  limit: 0,
  page: 0,
  params: [],
  filters: [],
  search: null,
  sort: null,
};

const Utils = {
  userHasPermission: (user, routePermissions) => {
    if (!routePermissions) return true;

    let hasPermission = false;

    const permissions = user.permissions.map((permission) => permission.name);
    routePermissions.forEach((permission) => {
      if (permissions.includes(permission)) {
        hasPermission = true;
      }
    });

    return hasPermission;
  },
  userHasRole: (user, routeRoles) => {
    if (!routeRoles) return true;

    let hasRole = false;
    const roles = user.roles.map((role) =>  role.name);
    routeRoles.forEach((role) => {
      if (roles.includes(role)) {
        hasRole = true;
      }
    });

    return hasRole;
  },
  getApiFilters(filtersCustom) {
    const filters = { ...apiGetFiltersDefault };
    if (filtersCustom != null) {
      filters.include =
        filtersCustom.include != null ? filtersCustom.include : filters.include;
      filters.page =
        filtersCustom.page != null ? filtersCustom.page : filters.page;
      filters.limit =
        filtersCustom.limit != null ? filtersCustom.limit : filters.limit;
      filters.params =
        filtersCustom.params != null ? filtersCustom.params : filters.params;
      filters.filters =
        filtersCustom.filters != null ? filtersCustom.filters : filters.filters;
      filters.search =
        filtersCustom.search != null ? filtersCustom.search : filters.search;
      filters.sort =
        filtersCustom.sort != null ? filtersCustom.sort : filters.sort;
    }
    return filters;
  },
};


export const getSlice = (listname = 'slice', data = null) => {
  const slice = {
    stateListName: listname,
    dataUrl: {},
    getFilters: {
      include: [],
      limit: 10,
      page: 0,
      params: [],
      filters: [],
      search: '',
      sort: '',
    },
    dataList: {
      showPageLoading: false,
      successHandle: null,
    },
    dataUpdate: {
      id: null,
      data: null,
      successHandle: null,
      disableAutoUpdate: false,
    },
    dataCreate: {
      data: null,
      successHandle: null,
    },
    dataDelete: {
      id: null,
      successHandle: null,
    },
    errorHandle: null,
  };

  return data ? merge(slice, data) : slice;
};


export default Utils;
